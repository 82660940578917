import toast from '~/utils/toast';
import { decodeReferralCode } from "~/utils/referral-code";

let template = ({customerId, customerName, job_application_url}) => `
<div class="text-xl">${customerName}</div>
<ul class="list-disc list-outside space-y-2 !mt-2">  
  <li><a class="text-blue-700 hover:text-blue-500 font-bold" target="_blank" href="/dashboard/${customerId}">Dashboard</a></li>
  <li><a class="text-blue-700 hover:text-blue-500 font-bold" target="_blank" href="/superadmin_dashboard/show/${customerId}">Superadmin Dashboard</a></li>
  <li><a class="text-blue-700 hover:text-blue-500 font-bold" target="_blank" href="${job_application_url}">Job Application</a></li>
  <li>Customer ID: ${customerId}</li>
</ul>`;

let renderReferrerTemplate = (args) => {
  let html = template(args);
  document.querySelector(".referrer-info").innerHTML = html;
}

let referralCodeElem = document.querySelector(".referral-code-to-decode");
if (referralCodeElem) {
  referralCodeElem.addEventListener("input", event => {
    let customerId = decodeReferralCode(referralCodeElem.value);
    fetch(`/api/v1/customer/${customerId}`)
      .then(res => res.json())
      .then(json => renderReferrerTemplate({customerId, ...json}))
  });
}